import React, {useEffect, useState} from "react";
import {getLanguage} from "../../util/PageUtil";
import {fetchData, fetchDataApiV2, isItUndefined} from "../../util/DataUtil";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";
import { getBackendUrl } from "../../util/Constants";
import { getGlossaryLanguage } from "../../util/DataUtil";

const MarketoForm = dynamic(() => import('./marketo-form'));

const TalkForm = ({lang, setShowTalkForm, showTalkForm, globalMetrics}) => {
    const [glossary, setGlossary] = useState(false);
    const [homeData, setHomeData] = useState(false);
    // const host = getBackendUrl(useSelector((state) => state.host.host));

    if(typeof window !== 'undefined'){
        var host = getBackendUrl(window.location.host); 
    }

    // let lang = '';

    // if (typeof window !== 'undefined') {
    //     lang = getLanguage(window.location.hostname);
    // }

    useEffect(() => {
        fetchGlossary();
        setHomeData(globalMetrics);
    }, []);

    // const fetchGlossary = async() => {
    //     // if(lang === 'fr' || lang === 'de') {
    //     //     const responseJson = await fetchData(host,"glossaries/v1/" + lang);
    //         const responseJson = await fetch(host.baseApiUrlRemote + "glossaries/v1/" + getGlossaryLanguage('de'))
    //         setGlossary(responseJson);
    //     // }
    // }

    const fetchGlossary = () => {
        if(lang !== 'en' && lang !== undefined){
            fetch(host?.baseApiUrlRemote + "glossaries/v1/" + getGlossaryLanguage(lang))
                .then((response) => {
                    if (!response.ok) {
                        return;
                    }
                    return response.json();
                })
                .then((gloss) => {
                    setGlossary(gloss || []);
                });
        }else {
            setGlossary([]);
        }
    }

    return(
        <div className={showTalkForm ? 'talk-form active' : 'talk-form'}>
            <div className={'title'
            }>
                {/*{homeData ? (homeData.field_contact_us_header ? homeData.field_contact_us_header[0]?.value : '') : ''}*/}
                {homeData ? (isItUndefined(homeData?.field_contact_us_form_header)) : ''}
                {/*Contact Us*/}
                <div onClick={() => {
                    setShowTalkForm(false)
                }} className={'escape'}>✕</div>
            </div>
            {
                showTalkForm
                ?
                <MarketoForm glossary={glossary} global={globalMetrics} />
                :
                ''
            }
        </div>
    );
}

export default TalkForm;
